exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-documents-politika-konfidencialnosti-index-jsx": () => import("./../../../src/pages/about/documents/politika_konfidencialnosti/index.jsx" /* webpackChunkName: "component---src-pages-about-documents-politika-konfidencialnosti-index-jsx" */),
  "component---src-pages-about-documents-soglashenie-ob-obrabotke-personalnyh-dannyh-index-jsx": () => import("./../../../src/pages/about/documents/soglashenie_ob_obrabotke_personalnyh_dannyh/index.jsx" /* webpackChunkName: "component---src-pages-about-documents-soglashenie-ob-obrabotke-personalnyh-dannyh-index-jsx" */),
  "component---src-pages-euro-site-info-data-processing-agreement-index-jsx": () => import("./../../../src/pages/euro-site/info/data_processing_agreement/index.jsx" /* webpackChunkName: "component---src-pages-euro-site-info-data-processing-agreement-index-jsx" */),
  "component---src-pages-euro-site-krp-molodostmozga-index-jsx": () => import("./../../../src/pages/euro-site/krp_molodostmozga/index.jsx" /* webpackChunkName: "component---src-pages-euro-site-krp-molodostmozga-index-jsx" */),
  "component---src-pages-euro-site-krp-tonusmozga-index-jsx": () => import("./../../../src/pages/euro-site/krp_tonusmozga/index.jsx" /* webpackChunkName: "component---src-pages-euro-site-krp-tonusmozga-index-jsx" */),
  "component---src-pages-euro-site-memory-development-index-jsx": () => import("./../../../src/pages/euro-site/memory_development/index.jsx" /* webpackChunkName: "component---src-pages-euro-site-memory-development-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marathon-emarafon-motivacii-i-obuchenija-detej-v-2-index-jsx": () => import("./../../../src/pages/marathon/emarafon_motivacii_i_obuchenija_detej_v2/index.jsx" /* webpackChunkName: "component---src-pages-marathon-emarafon-motivacii-i-obuchenija-detej-v-2-index-jsx" */),
  "component---src-pages-marathon-emarathon-intellektualnyj-privedi-svoj-mozg-v-tonus-11-index-jsx": () => import("./../../../src/pages/marathon/emarathon_intellektualnyj_privedi_svoj_mozg_v_tonus-11/index.jsx" /* webpackChunkName: "component---src-pages-marathon-emarathon-intellektualnyj-privedi-svoj-mozg-v-tonus-11-index-jsx" */),
  "component---src-pages-marathon-marafon-motivacii-i-obuchenija-detej-v-2-index-jsx": () => import("./../../../src/pages/marathon/marafon_motivacii_i_obuchenija_detej_v2/index.jsx" /* webpackChunkName: "component---src-pages-marathon-marafon-motivacii-i-obuchenija-detej-v-2-index-jsx" */),
  "component---src-pages-marathon-marathon-intellektualnyj-privedi-svoj-mozg-v-tonus-11-index-jsx": () => import("./../../../src/pages/marathon/marathon_intellektualnyj_privedi_svoj_mozg_v_tonus-11/index.jsx" /* webpackChunkName: "component---src-pages-marathon-marathon-intellektualnyj-privedi-svoj-mozg-v-tonus-11-index-jsx" */),
  "component---src-pages-marathon-mndu-index-jsx": () => import("./../../../src/pages/marathon/mndu/index.jsx" /* webpackChunkName: "component---src-pages-marathon-mndu-index-jsx" */),
  "component---src-pages-marathon-mndu-mini-index-jsx": () => import("./../../../src/pages/marathon/mndu-mini/index.jsx" /* webpackChunkName: "component---src-pages-marathon-mndu-mini-index-jsx" */),
  "component---src-pages-marathon-mndu-screen-index-jsx": () => import("./../../../src/pages/marathon/mndu-screen/index.jsx" /* webpackChunkName: "component---src-pages-marathon-mndu-screen-index-jsx" */),
  "component---src-pages-memory-2-index-jsx": () => import("./../../../src/pages/memory-2/index.jsx" /* webpackChunkName: "component---src-pages-memory-2-index-jsx" */),
  "component---src-pages-memory-3-index-jsx": () => import("./../../../src/pages/memory-3/index.jsx" /* webpackChunkName: "component---src-pages-memory-3-index-jsx" */),
  "component---src-pages-memory-4-index-jsx": () => import("./../../../src/pages/memory-4/index.jsx" /* webpackChunkName: "component---src-pages-memory-4-index-jsx" */),
  "component---src-pages-memory-5-index-jsx": () => import("./../../../src/pages/memory-5/index.jsx" /* webpackChunkName: "component---src-pages-memory-5-index-jsx" */),
  "component---src-pages-memory-6-index-jsx": () => import("./../../../src/pages/memory-6/index.jsx" /* webpackChunkName: "component---src-pages-memory-6-index-jsx" */),
  "component---src-pages-memory-7-index-jsx": () => import("./../../../src/pages/memory-7/index.jsx" /* webpackChunkName: "component---src-pages-memory-7-index-jsx" */),
  "component---src-pages-memory-8-index-jsx": () => import("./../../../src/pages/memory-8/index.jsx" /* webpackChunkName: "component---src-pages-memory-8-index-jsx" */),
  "component---src-pages-memory-9-index-jsx": () => import("./../../../src/pages/memory-9/index.jsx" /* webpackChunkName: "component---src-pages-memory-9-index-jsx" */),
  "component---src-pages-memory-index-jsx": () => import("./../../../src/pages/memory/index.jsx" /* webpackChunkName: "component---src-pages-memory-index-jsx" */)
}

